import React, { Fragment, useMemo, useState } from "react";
import CustomCard from "../../shared/CustomCard";
import BarChart from "./BarChart";
import { Dropdown } from "primereact/dropdown";
import AdvancedFilter from "../AdvancedFilter";

export default function BarChartCard({
  col,
  config,
  extraOptions,
  dataHour,
  dataDay,
  filters = true,
  view = "daily",
}) {
  const _title = config?.title;
  const [viewType, setViewType] = useState(view);

  const filter = (
    <div className="flex">
      <AdvancedFilter config={config} type="BAR" />
      <Dropdown
        className="custom-dropdown-filter p-inputtext-sm"
        value={viewType}
        onChange={(e) => setViewType(e.value)}
        options={[
          { name: "Daily", value: "daily" },
          { name: "Weekly", value: "weekly" },
          { name: "Monthly", value: "monthly" },
        ]}
        optionLabel="name"
      />
    </div>
  );

  const data = useMemo(() => {
    if (viewType === "daily") {
      return dataHour;
    } else if (viewType === "weekly") {
      return [...dataDay].splice(0, 7);
    } else {
      return dataDay;
    }
  }, [viewType, dataHour, dataDay]);
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title} header={filters && filter}>
          <div className="overflow-y-hidden">
            <BarChart config={config} data={data} extraOptions={extraOptions} />
          </div>
        </CustomCard>
      )}
    </Fragment>
  );
}
